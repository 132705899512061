import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Box, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const amount = [
  {
    id: 1,
    bal: "100",
  },
  {
    id: 2,
    bal: "200",
  },
  {
    id: 3,
    bal: "500",
  },
  {
    id: 4,
    bal: "1000",
  },
  {
    id: 5,
    bal: "5000",
  },
  {
    id: 6,
    bal: "10000",
  },
];

export default function Recharge() {
  const navigate = useNavigate();
  const location = useLocation();
  const userState = location?.state;
  // console.log(userState, "userState");

  const [balinput, setBalinput] = useState("");
  const [progress, setProgress] = useState(0);
  // console.log(balinput, "balinput");

  // const balData = () => {
  //   const formdata = new FormData();
  //   formdata.append("amount", balinput);
  //   formdata.append("utr", window?.$loginData);
  //   // formdata.append("upi", "321");
  //   balinput > 499
  //     ? axios
  //         .post(
  //           `${Fetch_Url}pay.php?user=${window?.$loginData}&am=${balinput}`,
  //           formdata,
  //           {}
  //         )
  //         .then((resp) => {
  //           console.log(resp, "resp data");
  //           toast.success(`Recharge Successfull`, {
  //             position: toast.POSITION.BOTTOM_LEFT,
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         })
  //     : toast.error("Input Empty Or Recharge Value at least 500", {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  // };

  const redirectUrl = () => {
    navigator.vibrate(100);
    balinput > 99
      ? window?.open(
        `https://games350.com/trova/src/api/pay.php?user=${window?.$loginData}&&am=${balinput}`,
        "_blank"
      )
      : toast.error("Input Empty Or Recharge Value at least 100", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 30); // Adjust this value to control the speed

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Recharge</h3>
        <i
          className="fa fa-align-left"
          onClick={() => {
            navigate("/rechargerecord");
          }}
        ></i>
      </div>
      <div className="balCont">
        <div className="balText">
          <h4>Your Records</h4>
        </div>
        <div className="circleFlex">
          <Box position="relative">
            <CircularProgress variant="determinate" value={progress} size={140} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption" component="div" color="textSecondary">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
          <Box position="relative">
            <CircularProgress variant="determinate" value={progress} size={140} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption" component="div" color="textSecondary">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
        </div>
        <div className="balInfo">
          <div className="balText">
            <h2>Balance: &#x20b9; {userState?.balance}</h2>
            <input
              type="number"
              placeholder="Enter Recharge Amount"
              value={balinput}
              onChange={(e) => {
                setBalinput(e.target.value);
              }}
            />
          </div>
          <div className="balBtns">
            {amount.map((item) => {
              return (
                <>
                  <button
                    onClick={() => {
                      setBalinput(item?.bal);
                    }}
                  >
                    &#x20b9; {item?.bal}
                  </button>
                </>
              );
            })}
          </div>
          {/* <div className="balText2">
          <h5>Payment</h5>
        </div>
        <div className="payText">
          <i className="fa fa-check"></i>
          <h5>EKPay</h5>
        </div> */}
          <div className="signinBtn" onClick={redirectUrl}>
            <button>Recharge</button>
          </div>
          <div className="balIcons">
            <div className="balIconInfo" onClick={() => { navigate("/transactions") }}>
              <PaidIcon />
              <h4>Transactions</h4>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/rechargerecord") }}>
              <PaymentIcon />
              <h4>Deposit History</h4>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/withdrawal", { state: userState }) }}>
              <CreditScoreIcon />
              <h4>Withdraw</h4>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/withrecord") }}>
              <LocalAtmIcon />
              <h4>Withdraw History</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* Recharge CSS */
