import BottomNav from "../BottomNavigate/BottomNav";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// mui
import Box from "@mui/material/Box";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import { wingo_Url } from "../../Context/Context";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Drawer from "@mui/material/Drawer";
import Modal from "@mui/material/Modal";
// import ReactAudioPlayer from "react-audio-player";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "20px"
};

const winPopup = {
  position: "fixed",
  top: "50%",
  left: "50%",
  right: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "20px"
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const moneydata = [
  {
    id: 1,
    money: 10,
  },
  {
    id: 2,
    money: 100,
  },
  {
    id: 3,
    money: 1000,
  },
  {
    id: 4,
    money: 10000,
  },
];

const numData = [
  {
    id: 1,
    num: 0,
    img: "zero.png",
  },
  {
    id: 2,
    num: 1,
    img: "one.png",
  },
  {
    id: 3,
    num: 2,
    img: "two.png",
  },
  {
    id: 4,
    num: 3,
    img: "three.png",
  },
  {
    id: 5,
    num: 4,
    img: "four.png",
  },
  {
    id: 6,
    num: 5,
    img: "five.png",
  },
  {
    id: 7,
    num: 6,
    img: "six.png",
  },
  {
    id: 8,
    num: 7,
    img: "seven.png",
  },
  {
    id: 9,
    num: 8,
    img: "eight.png",
  },
  {
    id: 10,
    num: 9,
    img: "nine.png",
  },
];

export default function Win() {
  const navigate = useNavigate()
  const [value, setValue] = useState(0);
  const [activeTab, setActiveTab] = useState("tab1");
  const [rule, setRule] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openNum, setOpenNum] = useState(false);
  const [openBig, setOpenBig] = useState(false);
  const [openSmall, setOpenSmall] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [userdetailWin, setUserdetailWin] = useState([]);
  const [resdata, setResdata] = useState([]);
  const [resdata2, setResdata2] = useState([]);
  const [resdata3, setResdata3] = useState([]);
  const [resdata4, setResdata4] = useState([]);
  const [betdata, setBetdata] = useState([]);
  const [betdata2, setBetdata2] = useState([]);
  const [betdata3, setBetdata3] = useState([]);
  const [betdata4, setBetdata4] = useState([]);
  const [thirtymin, setThirtymin] = useState(0);
  const [thirtysec, setThirtysec] = useState(30);
  const [printthirtysec, setPrintthirtysec] = useState(0);
  const [oneminute, setOneminute] = useState(0);
  const [onesecond, setOnesecond] = useState(60);
  const [printonesec, setPrintonesec] = useState(0);
  const [threemin, setThreemin] = useState(0);
  const [threesec, setThreesec] = useState(180);
  const [printthreesec, setPrintthreesec] = useState(0);
  const [fivemin, setFivemin] = useState(0);
  const [fivesec, setFivesec] = useState(300);
  const [printfivesec, setPrintfivesec] = useState(0);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [page3, setPage3] = useState(1);
  const [page4, setPage4] = useState(1);
  const [pageRes, setPageRes] = useState(1);
  const [pageRes2, setPageRes2] = useState(1);
  const [pageRes3, setPageRes3] = useState(1);
  const [pageRes4, setPageRes4] = useState(1);
  const [randomData, setRandomData] = useState([]);
  const [randomData2, setRandomData2] = useState([]);
  const [intvalue, setIntvalue] = useState(1);
  const [intvalue2, setIntvalue2] = useState(1);
  const [intvalue3, setIntvalue3] = useState(1);
  const [intvalue4, setIntvalue4] = useState(1);
  const [intvaluebig, setIntvaluebig] = useState(1);
  const [intvaluesmall, setIntvaluesmall] = useState(1);
  const [greenbtn, setGreenbtn] = useState({
    id: 1,
    money: 10,
  });
  const [violetbtn, setVioletbtn] = useState({
    id: 1,
    money: 10,
  });
  const [redbtn, setRedbtn] = useState({
    id: 1,
    money: 10,
  });
  const [bluebtn, setBluebtn] = useState({
    id: 1,
    money: 10,
  });
  const [bigbtn, setBigbtn] = useState({
    id: 1,
    money: 10,
  });
  const [smallbtn, setSmallbtn] = useState({
    id: 1,
    money: 10,
  });

  window.$loginData = localStorage.getItem("loginData");
  // console.log(window.$loginData, "logindata");

  // console.log(intvalue, greenbtn, "intvalue, greenbtn");
  let contractMoney = greenbtn?.money * intvalue;
  // console.log(contractMoney, "contractMoney");

  // console.log(intvalue2, violetbtn, "intvalue2, violetbtn");
  let contractMoney2 = violetbtn?.money * intvalue2;
  // console.log(contractMoney2, "contractMoney2");

  // console.log(intvalue3, redbtn, "intvalue3, redbtn");
  let contractMoney3 = redbtn?.money * intvalue3;
  // console.log(contractMoney3, "contractMoney3");

  // console.log(intvalue4, bluebtn, "intvalue4, bluebtn");
  let contractMoney4 = bluebtn?.money * intvalue4;
  // console.log(contractMoney4, "contractMoney4");

  // console.log(intvaluebig, bigbtn, "intvaluebig, bigbtn");
  let contractMoneybig = bigbtn?.money * intvaluebig;
  // console.log(contractMoneybig, "contractMoneybig");

  // console.log(intvaluesmall, smallbtn, "intvaluesmall, smallbtn");
  let contractMoneysmall = smallbtn?.money * intvaluesmall;
  // console.log(contractMoneybig, "contractMoneybig");

  const handleChange = (Item) => (event, isExpanded) => {
    setExpanded(isExpanded ? Item : false);
  };

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  const userInfo = () => {
    axios
      .post(`${wingo_Url}/bet.php?action=info&user=${window.$loginData}`, {})
      .then((resp) => {
        console.log(resp, "resp userInfo data");
        setUserdetailWin(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bal] = userdetailWin;
  // console.log(bal, "bal");

  const periodID1 = userdetailWin?.find((item) => {
    return item?.period_1;
  });
  // console.log(periodID1, "periodID1");

  const periodID2 = userdetailWin?.find((item) => {
    return item?.period_2;
  });
  // console.log(periodID2, "periodID2");

  const periodID3 = userdetailWin?.find((item) => {
    return item?.period_3;
  });
  // console.log(periodID3, "periodID3");

  const periodID4 = userdetailWin?.find((item) => {
    return item?.period_4;
  });
  // console.log(periodID4, "periodID4");

  const resultRecord = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=resultrec_1&page=${pageRes}`
    ).then((res) => res.json());
    const json = await res;
    // console.log(json, "res result data");
    setResdata(json);
  }, [pageRes]);

  useEffect(() => {
    resultRecord();
  }, [resultRecord]);

  const resultRecord2 = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=resultrec_2&page=${pageRes2}`
    ).then((res) => res.json());
    const json = await res;
    // console.log(json, "res result2 data");
    setResdata2(json);
  }, [pageRes2]);

  useEffect(() => {
    resultRecord2();
  }, [resultRecord2]);

  const resultRecord3 = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=resultrec_3&page=${pageRes3}`
    ).then((res) => res.json());
    const json = await res;
    // console.log(json, "res result3 data");
    setResdata3(json);
  }, [pageRes3]);

  useEffect(() => {
    resultRecord3();
  }, [resultRecord3]);

  const resultRecord4 = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=resultrec_4&page=${pageRes4}`
    ).then((res) => res.json());
    const json = await res;
    // console.log(json, "res result4 data");
    setResdata4(json);
  }, [pageRes4]);

  useEffect(() => {
    resultRecord4();
  }, [resultRecord4]);

  const betRecord = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=betrec_1&user=${window?.$loginData}&page=${page}`
    ).then((res) => res.json());
    const json = await res;
    console.log(json, "res bet data");
    setBetdata(json);
  }, [page]);

  React.useEffect(() => {
    betRecord();
  }, [betRecord]);

  const betRecord2 = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=betrec_2&user=${window?.$loginData}&page=${page2}`
    ).then((res) => res.json());
    const json = await res;
    // console.log(json, "res bet2 data");
    setBetdata2(json);
  }, [page2]);

  React.useEffect(() => {
    betRecord2();
  }, [betRecord2]);

  const betRecord3 = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=betrec_3&user=${window?.$loginData}&page=${page3}`
    ).then((res) => res.json());
    const json = await res;
    console.log(json, "res bet3 data");
    setBetdata3(json);
  }, [page3]);

  React.useEffect(() => {
    betRecord3();
  }, [betRecord3]);

  const betRecord4 = React.useCallback(async () => {
    const res = fetch(
      `${wingo_Url}/bet.php?action=betrec_4&user=${window?.$loginData}&page=${page4}`
    ).then((res) => res.json());
    const json = await res;
    console.log(json, "res bet4 data");
    setBetdata4(json);
  }, [page4]);

  React.useEffect(() => {
    betRecord4();
  }, [betRecord4]);

  const joinGreen = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID1?.period_1);
    formdata.append("amount", contractMoney);
    formdata.append("ans", "green");
    if (contractMoney <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_1`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinGreen resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpen(false);
            userInfo()
            betRecord()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinGreen2 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID2?.period_2);
    formdata.append("amount", contractMoney);
    formdata.append("ans", "green");
    if (contractMoney <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_2`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinGreen2 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpen(false);
            userInfo()
            betRecord2()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinGreen3 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID3?.period_3);
    formdata.append("amount", contractMoney);
    formdata.append("ans", "green");
    if (contractMoney <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_3`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinGreen3 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpen(false);
            userInfo()
            betRecord3()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinGreen4 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID4?.period_4);
    formdata.append("amount", contractMoney);
    formdata.append("ans", "green");
    if (contractMoney <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_4`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinGreen4 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpen(false);
            userInfo()
            betRecord4()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinViolet = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID1?.period_1);
    formdata.append("amount", contractMoney2);
    formdata.append("ans", "violet");
    contractMoney2 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_1`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinViolet resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen2(false);
          userInfo()
          betRecord()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinViolet2 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID2?.period_2);
    formdata.append("amount", contractMoney2);
    formdata.append("ans", "violet");
    contractMoney2 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_2`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinViolet2 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen2(false);
          userInfo()
          betRecord2()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinViolet3 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID3?.period_3);
    formdata.append("amount", contractMoney2);
    formdata.append("ans", "violet");
    contractMoney2 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_3`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinViolet3 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen2(false);
          userInfo()
          betRecord3()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinViolet4 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID4?.period_4);
    formdata.append("amount", contractMoney2);
    formdata.append("ans", "violet");
    contractMoney2 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_4`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinViolet4 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen2(false);
          userInfo()
          betRecord4()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinRed = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID1?.period_1);
    formdata.append("amount", contractMoney3);
    formdata.append("ans", "red");
    contractMoney3 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_1`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinRed resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen3(false);
          userInfo()
          betRecord()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinRed2 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID2?.period_2);
    formdata.append("amount", contractMoney3);
    formdata.append("ans", "red");
    contractMoney3 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_2`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinRed2 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen3(false);
          userInfo()
          betRecord2()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinRed3 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID3?.period_3);
    formdata.append("amount", contractMoney3);
    formdata.append("ans", "red");
    contractMoney3 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_3`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinRed3 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen3(false);
          userInfo()
          betRecord3()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinRed4 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID4?.period_4);
    formdata.append("amount", contractMoney3);
    formdata.append("ans", "red");
    contractMoney3 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_4`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinRed4 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen3(false);
          userInfo()
          betRecord4()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinBlue = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID1?.period_1);
    formdata.append("amount", contractMoney4);
    formdata.append("ans", openNum?.num);
    contractMoney4 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_1`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBlue resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpenNum(false);
          userInfo()
          betRecord()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinBlue2 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID2?.period_2);
    formdata.append("amount", contractMoney4);
    formdata.append("ans", openNum?.num);
    contractMoney4 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_2`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBlue2 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpenNum(false);
          userInfo()
          betRecord2()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinBlue3 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID3?.period_3);
    formdata.append("amount", contractMoney4);
    formdata.append("ans", openNum?.num);
    contractMoney4 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_3`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBlue3 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpenNum(false);
          userInfo()
          betRecord3()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinBlue4 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID4?.period_4);
    formdata.append("amount", contractMoney4);
    formdata.append("ans", openNum?.num);
    contractMoney4 <= bal?.balance
      ? axios
        .post(`${wingo_Url}/bet.php?action=bet_4`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBlue4 resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpenNum(false);
          userInfo()
          betRecord4()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinBig = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID1?.period_1);
    formdata.append("amount", contractMoneybig);
    formdata.append("ans", "big");
    if (contractMoneybig <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_1`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBig resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenBig(false);
            userInfo()
            betRecord()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinBig2 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID2?.period_2);
    formdata.append("amount", contractMoneybig);
    formdata.append("ans", "big");
    if (contractMoneybig <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_2`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBig2 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenBig(false);
            userInfo()
            betRecord2()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinBig3 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID3?.period_3);
    formdata.append("amount", contractMoneybig);
    formdata.append("ans", "big");
    if (contractMoneybig <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_3`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBig3 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenBig(false);
            userInfo()
            betRecord3()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinBig4 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID4?.period_4);
    formdata.append("amount", contractMoneybig);
    formdata.append("ans", "big");
    if (contractMoneybig <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_4`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBig4 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenBig(false);
            userInfo()
            betRecord4()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinSmall = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID1?.period_1);
    formdata.append("amount", contractMoneysmall);
    formdata.append("ans", "small");
    if (contractMoneysmall <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_1`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinSmall resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenSmall(false);
            userInfo()
            betRecord()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinSmall2 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID2?.period_2);
    formdata.append("amount", contractMoneysmall);
    formdata.append("ans", "small");
    if (contractMoneysmall <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_2`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinSmall2 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenSmall(false);
            userInfo()
            betRecord2()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinSmall3 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID3?.period_3);
    formdata.append("amount", contractMoneysmall);
    formdata.append("ans", "small");
    if (contractMoneysmall <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_3`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinSmall3 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenSmall(false);
            userInfo()
            betRecord3()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinSmall4 = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID4?.period_4);
    formdata.append("amount", contractMoneysmall);
    formdata.append("ans", "small");
    if (contractMoneysmall <= bal?.balance) {
      axios
        .post(`${wingo_Url}/bet.php?action=bet_4`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinSmall4 resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpenSmall(false);
            userInfo()
            betRecord4()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const generateRandomData = () => {
    const newData = [];
    const newData2 = [];
    for (let i = 0; i < 30; i++) {
      // Generate your random data here
      const randomValue = (Math?.floor(100000 + Math?.random() * 900000));
      const randomValue2 = (Math?.floor(1000 + Math?.random() * 9000));
      newData?.push(randomValue);
      newData2?.push(randomValue2);
    }
    setRandomData(newData);
    setRandomData2(newData2);
  };

  // 30 second game:-
  useEffect(() => {
    let interval = null;

    if (thirtysec > 0) {
      interval = setInterval(() => {
        setThirtysec(thirtysec - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [thirtysec]);

  useEffect(() => {
    const DateValue = Date.parse(new Date()) / 1e3;
    const h = 30 - (DateValue % 30);
    var i = h / 60,
      n = h % 60;
    setThirtymin(Math.floor(i));
    setPrintthirtysec(("0" + Math.floor(n)).slice(-2));
  });
  // console.log(thirtymin, printthirtysec, "thirtymin, printthirtysec");

  // 1Min Game:-
  useEffect(() => {
    let interval = null;

    if (onesecond > 0) {
      interval = setInterval(() => {
        setOnesecond(onesecond - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [onesecond]);

  useEffect(() => {
    const DateValue = Date.parse(new Date()) / 1e3;
    const h = 60 - (DateValue % 60);
    var i = h / 60,
      n = h % 60;
    setOneminute(Math.floor(i));
    setPrintonesec(("0" + Math.floor(n)).slice(-2));
  });
  // console.log(oneminute, printonesec, "oneminute, printonesec");

  // 3Min Game:-
  useEffect(() => {
    let interval = null;

    if (threesec > 0) {
      interval = setInterval(() => {
        setThreesec(threesec - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [threesec]);

  useEffect(() => {
    const DateValue = Date.parse(new Date()) / 1e3;
    const h = 180 - (DateValue % 180);
    var i = h / 60,
      n = h % 60;
    setThreemin(Math.floor(i));
    setPrintthreesec(("0" + Math.floor(n)).slice(-2));
  });
  // console.log(threemin, printthreesec, "threemin, printthreesec");

  // 5 min Game:-
  useEffect(() => {
    let interval = null;

    if (fivesec > 0) {
      interval = setInterval(() => {
        setFivesec(fivesec - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [fivesec]);

  useEffect(() => {
    const DateValue = Date.parse(new Date()) / 1e3;
    const h = 300 - (DateValue % 300);
    var i = h / 60,
      n = h % 60;
    setFivemin(Math.floor(i));
    setPrintfivesec(("0" + Math.floor(n)).slice(-2));
  });
  // console.log(fivemin, printfivesec, "fivemin, printfivesec");

  useEffect(() => {
    userInfo();
    generateRandomData()
  }, []);

  // 30 Seconds Game
  // const timerGame = () => {
  //   axios
  //     .get(`${wingo_Url}/game.php`)
  //     .then((resp) => {
  //       console.log(resp, "timerGame resp data");
  //     })
  //     .catch((err) => {
  //       console.log(err, "err");
  //     });
  // };

  // useEffect(() => {
  //   if (thirtymin === 0 && printthirtysec === "05") {
  //     timerGame()
  //     console.log("jhhjhjhjjhjkjkjkjk")
  //   }
  // }, [thirtymin, printthirtysec])

  // // 1 Min Game
  // const timerGame2 = () => {
  //   axios
  //     .get(`${wingo_Url}/game1.php`)
  //     .then((resp) => {
  //       console.log(resp, "timerGame2 resp data");
  //     })
  //     .catch((err) => {
  //       console.log(err, "err");
  //     });
  // };

  // useEffect(() => {
  //   if (oneminute === 0 && printonesec === "05") {
  //     timerGame2()
  //   }
  // }, [oneminute, printonesec])

  // // 3 Min Game
  // const timerGame3 = () => {
  //   axios
  //     .get(`${wingo_Url}/game2.php`)
  //     .then((resp) => {
  //       console.log(resp, "timerGame3 resp data");
  //     })
  //     .catch((err) => {
  //       console.log(err, "err");
  //     });
  // };

  // useEffect(() => {
  //   if (threemin === 0 && printthreesec === "05") {
  //     timerGame3()
  //   }
  // }, [threemin, printthreesec])

  // // 5 Min Game
  // const timerGame4 = () => {
  //   axios
  //     .get(`${wingo_Url}/game3.php`)
  //     .then((resp) => {
  //       console.log(resp, "timerGame4 resp data");
  //     })
  //     .catch((err) => {
  //       console.log(err, "err");
  //     });
  // };

  // useEffect(() => {
  //   if (fivemin === 0 && printfivesec === "05") {
  //     timerGame4()
  //   }
  // }, [fivemin, printfivesec])

  // update betrecord & PeriodId:-

  // 30 Seconds Game
  useEffect(() => {
    if (thirtymin === 0 && printthirtysec <= "05") {
      betRecord()
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "21") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "22") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "23") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "24") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "25") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "26") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  useEffect(() => {
    if (thirtymin === 0 && printthirtysec === "27") {
      userInfo();
      resultRecord();
    }
  }, [thirtymin, printthirtysec])

  // 1 Min Game
  useEffect(() => {
    if (oneminute === 0 && printonesec <= "05") {
      betRecord2()
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "51") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "52") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "53") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "54") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "55") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "56") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  useEffect(() => {
    if (oneminute === 0 && printonesec === "57") {
      userInfo();
      resultRecord2();
    }
  }, [oneminute, printonesec])

  // 3 Min Game
  useEffect(() => {
    if (threemin === 0 && printthreesec <= "05") {
      betRecord3()
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "51") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "52") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "53") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "54") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "55") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "56") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  useEffect(() => {
    if (threemin === 2 && printthreesec === "57") {
      userInfo();
      resultRecord3();
    }
  }, [threemin, printthreesec])

  // 5 Min Game
  useEffect(() => {
    if (fivemin === 0 && printfivesec <= "05") {
      betRecord4()
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "51") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "52") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "53") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "54") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "55") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "56") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  useEffect(() => {
    if (fivemin === 4 && printfivesec === "57") {
      userInfo();
      resultRecord4();
    }
  }, [fivemin, printfivesec])

  const betpopup = betdata[0]
  // console.log(betdata, "betpopup")

  const betpopup2 = betdata2[0]
  // console.log(betpopup2, "betpopup2")

  const betpopup3 = betdata3[0]
  // console.log(betpopup3, "betpopup3")

  const betpopup4 = betdata4[0]
  // console.log(betpopup4, "betpopup4")

  return (
    <>
      <ToastContainer />
      <BottomNav />
      <div className="gameTop">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate("/");
          }}
        ></i>
        <h3>Win Go</h3>
        <h4>
          &#8377; {bal?.balance}
        </h4>
        <i
          className="fa fa-credit-card"
          onClick={() => {
            navigate("/recharge", { state: bal }
            );
          }}
        ></i>
      </div>
      <div className="winCont">
        <div className="winParity">
          <h5>Win Go</h5>
        </div>
        <div className="wintopbox">
          <div className="wintopbal">
            <h3>&#x20b9; {bal?.balance}</h3>
            <h5>Wallet Balance</h5>
          </div>
          <div className="wintopbtn">
            <Link to="/withdrawal" state={bal}>
              <div className="btn1">
                <button
                  onClick={() => {
                    navigator.vibrate(100);
                  }}
                >
                  Withdraw
                </button>
              </div>
            </Link>
            <Link to="/recharge" state={bal}>
              <div className="btn2">
                <button
                  onClick={() => {
                    navigator.vibrate(100);
                  }}
                >
                  Deposit
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="winTabs">
          <div className={activeTab === "tab1" ? "clickWatch" : "winWatch"} onClick={() => setActiveTab("tab1")}>
            <img src="wintime.png" alt="" />
            <h4>Win Go <br />30s</h4>
          </div>
          <div className={activeTab === "tab2" ? "clickWatch" : "winWatch"} onClick={() => setActiveTab("tab2")}>
            <img src="wintime.png" alt="" />
            <h4>Win Go <br />1Min</h4>
          </div>
          <div className={activeTab === "tab3" ? "clickWatch" : "winWatch"} onClick={() => setActiveTab("tab3")}>
            <img src="wintime.png" alt="" />
            <h4>Win Go <br />3Min</h4>
          </div>
          <div className={activeTab === "tab4" ? "clickWatch" : "winWatch"} onClick={() => setActiveTab("tab4")}>
            <img src="wintime.png" alt="" />
            <h4>Win Go <br />5Min</h4>
          </div>
        </div>
        {activeTab === "tab1" &&
          <div>
            <div className="winTimebox">
              <div className="winTxtBox">
                <div className="winTxt">
                  <h5 onClick={() => {
                    setRule(true)
                  }}>How to Play</h5>
                  <h6>Win Go 3o Seconds</h6>
                </div>
                <Modal
                  open={rule}
                  onClose={() => {
                    setRule(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="diceRule">
                      <h2>How to Play</h2>
                    </div>
                    <div className="diceRuleInfo">
                      <h4>1 minutes 1 issue, 50 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <h4>2 minutes 2 issue, 100 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <button onClick={() => {
                        setRule(false)
                      }}>Close</button>
                    </div>
                  </Box>
                </Modal>
                <div className="winPlay">
                  <img src="one.png" alt="" />
                  <img src="three.png" alt="" />
                  <img src="two.png" alt="" />
                  <img src="eight.png" alt="" />
                  <img src="one.png" alt="" />
                </div>
              </div>
              <div className="winCount">
                <h5>Time Remaining</h5>
                <h3>
                  <span>0{thirtymin}</span> <span>:</span> <span>{printthirtysec}</span>
                </h3>
                <h6>{periodID1?.period_1}</h6>
              </div>
            </div>
            <div className="hideParent">
              <div>
                <div className="winMinicont">
                  <div className="joinBtn">
                    <div>
                      <button
                        className={thirtymin === 0 && printthirtysec <= "05" ? "greyBtn" : "joinBtn1"}
                        onClick={() => {
                          thirtymin === 0 && printthirtysec <= "05"
                            ? setOpen(false)
                            : setOpen(true);
                        }}
                      >
                        Join Green
                      </button>
                      <Drawer open={open} anchor="bottom">
                        <div>
                          <div className="greenBot">
                            <h4>Win Go 30s</h4>
                            <h5>Select Green</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === greenbtn?.id ? "clickedMoney" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setGreenbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  disabled={intvalue <= 1}
                                  onClick={() => {
                                    setIntvalue(intvalue - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue}</h3>
                                <button
                                  onClick={() => {
                                    setIntvalue(intvalue + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button onClick={() => {
                              setOpen(false)
                            }}>Cancel</button>
                            <button style={{ background: "#17b15e" }} onClick={joinGreen}>Total Amount &#8377;{contractMoney}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          thirtymin === 0 && printthirtysec < "05" ? "greyBtn" : "joinBtn2"
                        }
                        onClick={() => {
                          thirtymin === 0 && printthirtysec < "05"
                            ? setOpen2(false)
                            : setOpen2(true);
                        }}
                      >
                        Join Violet
                      </button>
                      <Drawer open={open2} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#9b48db" }}>
                            <h4>Win Go 30s</h4>
                            <h5>Select Violet</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === violetbtn?.id ? "violetColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setVioletbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#9b48db" }}
                                  disabled={intvalue2 <= 1}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue2}</h3>
                                <button
                                  style={{ background: "#9b48db" }}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen2(false);
                              }}>Cancel</button>
                            <button style={{ background: "#9b48db" }} onClick={joinViolet}>Total Amount &#8377;{contractMoney2}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          thirtymin === 0 && printthirtysec < "05" ? "greyBtn" : "joinBtn3"
                        }
                        onClick={() => {
                          thirtymin === 0 && printthirtysec < "05"
                            ? setOpen3(false)
                            : setOpen3(true);
                        }}
                      >
                        Join Red
                      </button>
                      <Drawer open={open3} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#d23838" }}>
                            <h4>Win Go 30s</h4>
                            <h5>Select Red</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === redbtn?.id ? "redColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setRedbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#d23838" }}
                                  disabled={intvalue3 <= 1}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue3}</h3>
                                <button
                                  style={{ background: "#d23838" }}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen3(false);
                              }}>Cancel</button>
                            <button style={{ background: "#d23838" }} onClick={joinRed}>Total Amount &#8377;{contractMoney3}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                  <div className="winNumbers">
                    {numData?.map((item) => {
                      return (
                        <>
                          <img
                            src={item?.img}
                            alt=""
                            onClick={() => {
                              thirtymin === 0 && printthirtysec <= "05"
                                ? setOpenNum(false)
                                : setOpenNum(true) || setOpenNum(item);
                            }}
                          />
                        </>
                      );
                    })}
                    <Drawer open={openNum} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#454e9e" }}>
                          <h4>Win Go</h4>
                          <h5>Select {openNum?.num}</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bluebtn?.id ? "numColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBluebtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#454e9e" }}
                                disabled={intvalue4 <= 1}
                                onClick={() => {
                                  setIntvalue4(intvalue4 - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvalue4}</h3>
                              <button
                                style={{ background: "#454e9e" }}
                                onClick={() => {
                                  setIntvalue4(intvalue4 + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenNum(false)
                          }}>Cancel</button>
                          <button style={{ background: "#454e9e", borderLeft: "2px solid white" }} onClick={joinBlue}>Total Amount &#8377;{contractMoney4}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
                <div className="bigsmall">
                  <div className="bigsmall1">
                    <button
                      onClick={() => {
                        thirtymin === 0 && printthirtysec <= "05"
                          ? setOpenBig(false)
                          : setOpenBig(true);
                      }}>Big</button>
                    <Drawer open={openBig} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#dd9138" }}>
                          <h4>Win Go</h4>
                          <h5>Select Big</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bigbtn?.id ? "bigColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBigbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#dd9138" }}
                                disabled={intvaluebig <= 1}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluebig}</h3>
                              <button
                                style={{ background: "#dd9138" }}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenBig(false)
                          }}>Cancel</button>
                          <button style={{ background: "#dd9138" }} onClick={joinBig}>Total Amount &#8377;{contractMoneybig}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                  <div className="bigsmall2">
                    <button
                      onClick={() => {
                        thirtymin === 0 && printthirtysec <= "05"
                          ? setOpenSmall(false)
                          : setOpenSmall(true);
                      }}
                    >Small</button>
                    <Drawer open={openSmall} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#5088d3" }}>
                          <h4>Win Go</h4>
                          <h5>Select Small</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === smallbtn?.id ? "smallMoney" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setSmallbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#5088d3" }}
                                disabled={intvaluesmall <= 1}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluesmall}</h3>
                              <button
                                style={{ background: "#5088d3" }}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenSmall(false)
                          }}>Cancel</button>
                          <button style={{ background: "#5088d3" }} onClick={joinSmall}>Total Amount &#8377;{contractMoneysmall}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
              {/*  {(periodID1?.period_1 === betpopup?.period) && (thirtymin === 0 && printthirtysec <= "05") && (thirtymin === 0 && printthirtysec >= "03") &&
                <div className="hideBg">
                  <h4>{printthirtysec}</h4>
                </div>
              } */}
              {(thirtymin === 0 && printthirtysec <= "05") &&
                <div className="hideBg">
                  <h4>{printthirtysec}</h4>
                </div>
              }
            </div>
            <Box
              sx={{
                width: "100%",
                marginTop: "15px"
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange2}
                aria-label="basic tabs example"
                className="gameTabs"
              >
                <Tab label="Parity Records" {...a11yProps(0)} />
                <Tab label="All Records" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>WinGo Record</h5>
                </div>
                <Paper>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Period</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Numbers</TableCell>
                          <TableCell align="right">Big Small</TableCell>
                          <TableCell align="right">Result</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resdata?.map((item) => (
                          <TableRow key={item?.name}>
                            <TableCell component="th" scope="row">
                              {item?.period}
                            </TableCell>
                            <TableCell align="right">{item?.num}</TableCell>
                            <TableCell align="right">
                              <div>
                                <h4 style={{ paddingTop: "6px" }}
                                  className={
                                    item?.ans === "0"
                                      ? "redColor"
                                      : "greenColor" && item?.ans === "2"
                                        ? "redColor"
                                        : "greenColor" && item?.ans === "4"
                                          ? "redColor"
                                          : "greenColor" && item?.ans === "6"
                                            ? "redColor"
                                            : "greenColor" && item?.ans === "8"
                                              ? "redColor"
                                              : "greenColor"
                                  }
                                >
                                  {item?.ans}
                                </h4>
                              </div>
                            </TableCell>
                            <TableCell align="right">{item?.ans <= 4 ? 'Small' : 'Big'}</TableCell>
                            <TableCell align="right">
                              <div className="tableColor">
                                <h4
                                  className={
                                    item?.clo === "red" ? "colorRed" : "colorGreen"
                                  }
                                ></h4>
                                <h4
                                  className={item?.res1 === "violet" && "colorViolet"}
                                ></h4>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div className="pageBtn">
                  <button
                    onClick={() => setPageRes(pageRes - 1)}
                    disabled={pageRes === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <button onClick={() => setPageRes(pageRes + 1)}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </TabPanel >
            <TabPanel value={value} index={1}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>All Records</h5>
                </div>
                <div className="tableAll">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: "14px" }}>User</th>
                        <th style={{ padding: "14px" }}>Bet</th>
                        <th style={{ padding: "14px" }}>Multi.</th>
                        <th style={{ padding: "14px" }}>Cash Out</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {randomData?.map((item) => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  <i className="fa fa-user"
                                    style={{ fontSize: "15px" }}
                                  >
                                  </i> &nbsp;
                                  {item}
                                </div>
                              </>
                            )
                          })}
                        </td>
                        {randomData2?.map((item) => {
                          return (
                            <>
                              <div style={{ padding: "10px", margin: "1px" }}>
                                &#8377;{item}
                              </div>
                            </>
                          )
                        })}
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <div className="betCont">
              <div className="winRecord">
                <i className="fa fa-book"></i>
                <h5>My Record</h5>
              </div>
              <Paper>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {betdata?.map((item) => {
                      return (
                        <>
                          <Accordion
                            expanded={expanded === item}
                            onChange={handleChange(item)}
                            sx={{
                              background: "#2b3270"
                            }}
                          >
                            <TableHead>
                              <TableRow className="betIcon">
                                <AccordionSummary
                                  expandIcon={
                                    <div className="expandIcon2">
                                      <i className="fa fa-angle-down"></i>
                                    </div>
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "98fr 2fr",
                                    background: "#2b3270"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      paddingLeft: "1px",
                                      flexShrink: 0,
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.period}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      marginLeft: "15px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.res}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {item?.ans === "violet" && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "violet" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 5 -
                                        (item?.amount * 5 * 5) / 100)
                                      : ""}

                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {!isNaN(item?.ans) && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {!isNaN(item?.ans) && item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 8 -
                                        (item?.amount * 8 * 5) / 100)
                                      : ""}

                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}

                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                  </Typography>
                                </AccordionSummary>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <AccordionDetails
                                  sx={{ background: "2b3270" }}>
                                  <div className="betHead">
                                    <h4>Period Detail</h4>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Period</h6>
                                    <h6>{item?.period}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Contract Money</h6>
                                    <h6>{item?.amount}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Delivery</h6>
                                    <h6>
                                      {item?.amount - (item?.amount * 5) / 100}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Fee</h6>
                                    <h6>{(item?.amount * 5) / 100}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Open Price</h6>
                                    <h6>{item?.price}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Result</h6>
                                    <h6>
                                      {item?.number} {item?.color}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Select</h6>
                                    <h6>{item?.ans}</h6>
                                  </div>
                                  <div
                                    className={
                                      (item?.res === "fail" &&
                                        "redColor betRecord") ||
                                      (item?.res === "wait" &&
                                        "orangeColor betRecord") ||
                                      (item?.res === "success" &&
                                        "greenColor betRecord")
                                    }
                                  >
                                    <h6>Status</h6>
                                    <h5>{item?.res}</h5>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Amount</h6>
                                    <h6>
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {item?.ans === "violet" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "violet" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 5 -
                                          (item?.amount * 5 * 5) / 100)
                                        : ""}

                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {!isNaN(item?.ans) && item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {!isNaN(item?.ans) && item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 8 -
                                          (item?.amount * 8 * 5) / 100)
                                        : ""}

                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}

                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Create Time</h6>
                                    <h6>{item?.time}</h6>
                                  </div>
                                </AccordionDetails>
                              </TableRow>
                            </TableBody>
                          </Accordion>
                        </>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Paper>
              <div className="pageBtn">
                <button onClick={() => setPage(page - 1)} disabled={page === 1}>
                  <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPage(page + 1)}>
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
            {(periodID1?.period_1 === betpopup?.period) && (thirtymin === 0 && printthirtysec >= "28") &&
              <div className="sorrycard" style={winPopup}>
                {betpopup?.res === "fail" ?
                  <img src="sorrycard.png" alt="" />
                  :
                  <img src="wincard.png" alt="" />
                }
                <div className="sorrycardTxt">
                  <h3 className={betpopup?.res === "fail" ? "blueColor" : "yellowColor"}>{betpopup?.res === "fail" ? "Sorry" : "Congratulations"}</h3>
                  <h4>{betpopup?.res === "fail" ? "Loss" : "Bonus"}</h4>
                  <h5 className={betpopup?.res === "fail" ? "blueColor" : "newredColor"}>&#8377;
                    {betpopup?.ans === "green" &&
                      betpopup?.color2 == "" &&
                      betpopup?.res === "fail"
                      ? -(betpopup?.amount - (betpopup?.amount * 5) / 100)
                      : ""}
                    {betpopup?.ans === "green" &&
                      betpopup?.color2 == "" &&
                      betpopup?.res === "success"
                      ? "+" +
                      (betpopup?.amount * 2 -
                        (betpopup?.amount * 2 * 5) / 100)
                      : ""}
                    {betpopup?.ans === "violet" &&
                      betpopup?.res === "fail"
                      ? -(betpopup?.amount - (betpopup?.amount * 5) / 100)
                      : ""}
                    {betpopup?.ans === "violet" &&
                      betpopup?.res === "success"
                      ? "+" +
                      (betpopup?.amount * 5 -
                        (betpopup?.amount * 5 * 5) / 100)
                      : ""}
                    {betpopup?.ans === "red" &&
                      betpopup?.color2 == "" &&
                      betpopup?.res === "fail"
                      ? -(betpopup?.amount - (betpopup?.amount * 5) / 100)
                      : ""}
                    {betpopup?.ans === "red" &&
                      betpopup?.color2 == "" &&
                      betpopup?.res === "success"
                      ? "+" +
                      (betpopup?.amount * 2 -
                        (betpopup?.amount * 2 * 5) / 100)
                      : ""}
                    {!isNaN(betpopup?.ans) && betpopup?.res === "fail"
                      ? -(betpopup?.amount - (betpopup?.amount * 5) / 100)
                      : ""}
                    {!isNaN(betpopup?.ans) && betpopup?.res === "success"
                      ? "+" +
                      (betpopup?.amount * 8 -
                        (betpopup?.amount * 8 * 5) / 100)
                      : ""}
                    {betpopup?.ans == "red" &&
                      betpopup?.color2 == "violet" &&
                      betpopup?.res == "success"
                      ? "+" +
                      (betpopup?.amount * 1.5 -
                        (betpopup?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup?.ans == "red" &&
                      betpopup?.color2 == "violet" &&
                      betpopup?.res == "fail"
                      ? -(betpopup?.amount - (betpopup?.amount * 5) / 100)
                      : ""}
                    {betpopup?.ans == "green" &&
                      betpopup?.color2 == "violet" &&
                      betpopup?.res == "success"
                      ? "+" +
                      (betpopup?.amount * 1.5 -
                        (betpopup?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup?.ans == "green" &&
                      betpopup?.color2 == "violet" &&
                      betpopup?.res == "fail"
                      ? -(betpopup?.amount - (betpopup?.amount * 5) / 100)
                      : ""}
                  </h5>
                  <h6>Period ID: {betpopup?.period} </h6>
                </div>
              </div>
            }
          </div>
        }
        {activeTab === "tab2" &&
          <div>
            <div className="winTimebox">
              <div className="winTxtBox">
                <div className="winTxt">
                  <h5 onClick={() => { setRule(true) }}>How to Play</h5>
                  <h6>Win Go 1 Min</h6>
                </div>
                <Modal
                  open={rule}
                  onClose={() => {
                    setRule(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="diceRule">
                      <h2>How to Play</h2>
                    </div>
                    <div className="diceRuleInfo">
                      <h4>1 minutes 1 issue, 50 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <h4>2 minutes 2 issue, 100 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <button onClick={() => {
                        setRule(false)
                      }}>Close</button>
                    </div>
                  </Box>
                </Modal>
                <div className="winPlay">
                  <img src="one.png" alt="" />
                  <img src="three.png" alt="" />
                  <img src="two.png" alt="" />
                  <img src="eight.png" alt="" />
                  <img src="one.png" alt="" />
                </div>
              </div>
              <div className="winCount">
                <h5>Time Remaining</h5>
                <h3>
                  <span>0{oneminute}</span> <span>:</span> <span>{printonesec}</span>
                </h3>
                <h6>{periodID2?.period_2}</h6>
              </div>
            </div>
            <div className="hideParent">
              <div>
                <div className="winMinicont">
                  <div className="joinBtn">
                    <div>
                      <button
                        className={oneminute === 0 && printonesec <= "05" ? "greyBtn" : "joinBtn1"}
                        onClick={() => {
                          oneminute === 0 && printonesec <= "05"
                            ? setOpen(false)
                            : setOpen(true);
                        }}
                      >
                        Join Green
                      </button>
                      <Drawer open={open} anchor="bottom">
                        <div>
                          <div className="greenBot">
                            <h4>Win Go 1Min</h4>
                            <h5>Select Green</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === greenbtn?.id ? "clickedMoney" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setGreenbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  disabled={intvalue <= 1}
                                  onClick={() => {
                                    setIntvalue(intvalue - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue}</h3>
                                <button
                                  onClick={() => {
                                    setIntvalue(intvalue + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button onClick={() => {
                              setOpen(false)
                            }}>Cancel</button>
                            <button style={{ background: "#17b15e" }} onClick={joinGreen2}>Total Amount &#8377;{contractMoney}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          oneminute === 0 && printonesec < "05" ? "greyBtn" : "joinBtn2"
                        }
                        onClick={() => {
                          oneminute === 0 && printonesec < "05"
                            ? setOpen2(false)
                            : setOpen2(true);
                        }}
                      >
                        Join Violet
                      </button>
                      <Drawer open={open2} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#9b48db" }}>
                            <h4>Win Go 1Min</h4>
                            <h5>Select Violet</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === violetbtn?.id ? "violetColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setVioletbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#9b48db" }}
                                  disabled={intvalue2 <= 1}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue2}</h3>
                                <button
                                  style={{ background: "#9b48db" }}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen2(false);
                              }}>Cancel</button>
                            <button style={{ background: "#9b48db" }} onClick={joinViolet2}>Total Amount &#8377;{contractMoney2}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          oneminute === 0 && printonesec < "05" ? "greyBtn" : "joinBtn3"
                        }
                        onClick={() => {
                          oneminute === 0 && printonesec < "05"
                            ? setOpen3(false)
                            : setOpen3(true);
                        }}
                      >
                        Join Red
                      </button>
                      <Drawer open={open3} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#d23838" }}>
                            <h4>Win Go 1Min</h4>
                            <h5>Select Red</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === redbtn?.id ? "redColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setRedbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#d23838" }}
                                  disabled={intvalue3 <= 1}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue3}</h3>
                                <button
                                  style={{ background: "#d23838" }}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen3(false);
                              }}>Cancel</button>
                            <button style={{ background: "#d23838" }} onClick={joinRed2}>Total Amount &#8377;{contractMoney3}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                  <div className="winNumbers">
                    {numData?.map((item) => {
                      return (
                        <>
                          <img
                            src={item?.img}
                            alt=""
                            onClick={() => {
                              oneminute === 0 && printonesec <= "05"
                                ? setOpenNum(false)
                                : setOpenNum(true) || setOpenNum(item);
                            }}
                          />
                        </>
                      );
                    })}
                    <Drawer open={openNum} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#454e9e" }}>
                          <h4>Win Go</h4>
                          <h5>Select {openNum?.num}</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bluebtn?.id ? "numColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBluebtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#454e9e" }}
                                disabled={intvalue4 <= 1}
                                onClick={() => {
                                  setIntvalue4(intvalue4 - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvalue4}</h3>
                              <button
                                style={{ background: "#454e9e" }}
                                onClick={() => {
                                  setIntvalue4(intvalue4 + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenNum(false)
                          }}>Cancel</button>
                          <button style={{ background: "#454e9e", borderLeft: "2px solid white" }} onClick={joinBlue2}>Total Amount &#8377;{contractMoney4}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
                <div className="bigsmall">
                  <div className="bigsmall1">
                    <button
                      onClick={() => {
                        oneminute === 0 && printonesec <= "05"
                          ? setOpenBig(false)
                          : setOpenBig(true);
                      }}>Big</button>
                    <Drawer open={openBig} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#dd9138" }}>
                          <h4>Win Go</h4>
                          <h5>Select Big</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bigbtn?.id ? "bigColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBigbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#dd9138" }}
                                disabled={intvaluebig <= 1}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluebig}</h3>
                              <button
                                style={{ background: "#dd9138" }}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenBig(false)
                          }}>Cancel</button>
                          <button style={{ background: "#dd9138" }} onClick={joinBig2}>Total Amount &#8377;{contractMoneybig}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                  <div className="bigsmall2">
                    <button
                      onClick={() => {
                        oneminute === 0 && printonesec <= "05"
                          ? setOpenSmall(false)
                          : setOpenSmall(true);
                      }}
                    >Small</button>
                    <Drawer open={openSmall} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#5088d3" }}>
                          <h4>Win Go</h4>
                          <h5>Select Small</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === smallbtn?.id ? "smallMoney" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setSmallbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#5088d3" }}
                                disabled={intvaluesmall <= 1}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluesmall}</h3>
                              <button
                                style={{ background: "#5088d3" }}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenSmall(false)
                          }}>Cancel</button>
                          <button style={{ background: "#5088d3" }} onClick={joinSmall2}>Total Amount &#8377;{contractMoneysmall}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
              {/* {(periodID2?.period_2 === betpopup2?.period) && (oneminute === 0 && printonesec <= "05") && (oneminute === 0 && printonesec >= "03") &&
                <div className="hideBg">
                  <h4>{printonesec}</h4>
                </div>
              } */}
              {(oneminute === 0 && printonesec <= "05") &&
                <div className="hideBg">
                  <h4>{printonesec}</h4>
                </div>
              }
            </div>
            <Box
              sx={{
                width: "100%",
                marginTop: "15px"
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange2}
                aria-label="basic tabs example"
                className="gameTabs"
              >
                <Tab label="Parity Records" {...a11yProps(0)} />
                <Tab label="All Records" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>WinGo Record</h5>
                </div>
                <Paper>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Period</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Numbers</TableCell>
                          <TableCell align="right">Big Small</TableCell>
                          <TableCell align="right">Result</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resdata2?.map((item) => (
                          <TableRow key={item?.name}>
                            <TableCell component="th" scope="row">
                              {item?.period}
                            </TableCell>
                            <TableCell align="right">{item?.num}</TableCell>
                            <TableCell align="right">
                              <div>
                                <h4 style={{ paddingTop: "6px" }}
                                  className={
                                    item?.ans === "0"
                                      ? "redColor"
                                      : "greenColor" && item?.ans === "2"
                                        ? "redColor"
                                        : "greenColor" && item?.ans === "4"
                                          ? "redColor"
                                          : "greenColor" && item?.ans === "6"
                                            ? "redColor"
                                            : "greenColor" && item?.ans === "8"
                                              ? "redColor"
                                              : "greenColor"
                                  }
                                >
                                  {item?.ans}
                                </h4>
                              </div>
                            </TableCell>
                            <TableCell align="right">{item?.ans <= 4 ? 'Small' : 'Big'}</TableCell>
                            <TableCell align="right">
                              <div className="tableColor">
                                <h4
                                  className={
                                    item?.clo === "red" ? "colorRed" : "colorGreen"
                                  }
                                ></h4>
                                <h4
                                  className={item?.res1 === "violet" && "colorViolet"}
                                ></h4>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div className="pageBtn">
                  <button
                    onClick={() => setPageRes2(pageRes2 - 1)}
                    disabled={pageRes2 === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <button onClick={() => setPageRes2(pageRes2 + 1)}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </TabPanel >
            <TabPanel value={value} index={1}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>All Records</h5>
                </div>
                <div className="tableAll">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: "14px" }}>User</th>
                        <th style={{ padding: "14px" }}>Bet</th>
                        <th style={{ padding: "14px" }}>Multi.</th>
                        <th style={{ padding: "14px" }}>Cash Out</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {randomData?.map((item) => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  <i className="fa fa-user"
                                    style={{ fontSize: "15px" }}
                                  >
                                  </i> &nbsp;
                                  {item}
                                </div>
                              </>
                            )
                          })}
                        </td>
                        {randomData2?.map((item) => {
                          return (
                            <>
                              <div style={{ padding: "10px", margin: "1px" }}>
                                &#8377;{item}
                              </div>
                            </>
                          )
                        })}
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <div className="betCont">
              <div className="winRecord">
                <i className="fa fa-book"></i>
                <h5>My Record</h5>
              </div>
              <Paper>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {betdata2?.map((item) => {
                      return (
                        <>
                          <Accordion
                            expanded={expanded === item}
                            onChange={handleChange(item)}
                            sx={{
                              background: "#2b3270"
                            }}
                          >
                            <TableHead>
                              <TableRow className="betIcon">
                                <AccordionSummary
                                  expandIcon={
                                    <div className="expandIcon2">
                                      <i className="fa fa-angle-down"></i>
                                    </div>
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "98fr 2fr",
                                    background: "#2b3270"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      paddingLeft: "1px",
                                      flexShrink: 0,
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.period}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      marginLeft: "15px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.res}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {item?.ans === "violet" && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "violet" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 5 -
                                        (item?.amount * 5 * 5) / 100)
                                      : ""}

                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {!isNaN(item?.ans) && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {!isNaN(item?.ans) && item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 8 -
                                        (item?.amount * 8 * 5) / 100)
                                      : ""}

                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}

                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                  </Typography>
                                </AccordionSummary>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <AccordionDetails
                                  sx={{
                                    background: "#2b3270"
                                  }}
                                >
                                  <div className="betHead">
                                    <h4>Period Detail</h4>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Period</h6>
                                    <h6>{item?.period}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Contract Money</h6>
                                    <h6>{item?.amount}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Delivery</h6>
                                    <h6>
                                      {item?.amount - (item?.amount * 5) / 100}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Fee</h6>
                                    <h6>{(item?.amount * 5) / 100}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Open Price</h6>
                                    <h6>{item?.price}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Result</h6>
                                    <h6>
                                      {item?.number} {item?.color}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Select</h6>
                                    <h6>{item?.ans}</h6>
                                  </div>
                                  <div
                                    className={
                                      (item?.res === "fail" &&
                                        "redColor betRecord") ||
                                      (item?.res === "wait" &&
                                        "orangeColor betRecord") ||
                                      (item?.res === "success" &&
                                        "greenColor betRecord")
                                    }
                                  >
                                    <h6>Status</h6>
                                    <h5>{item?.res}</h5>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Amount</h6>
                                    <h6>
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {item?.ans === "violet" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "violet" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 5 -
                                          (item?.amount * 5 * 5) / 100)
                                        : ""}

                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {!isNaN(item?.ans) && item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {!isNaN(item?.ans) && item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 8 -
                                          (item?.amount * 8 * 5) / 100)
                                        : ""}

                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}

                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Create Time</h6>
                                    <h6>{item?.time}</h6>
                                  </div>
                                </AccordionDetails>
                              </TableRow>
                            </TableBody>
                          </Accordion>
                        </>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Paper>
              <div className="pageBtn">
                <button onClick={() => setPage2(page2 - 1)} disabled={page2 === 1}>
                  <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPage2(page2 + 1)}>
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
            {((periodID2?.period_2 === betpopup2?.period) && (oneminute === 0 && printonesec >= "58" || oneminute === 1 && printonesec === "00")) &&
              <div className="sorrycard" style={winPopup}>
                {betpopup2?.res === "fail" ?
                  <img src="sorrycard.png" alt="" />
                  :
                  <img src="wincard.png" alt="" />
                }
                <div className="sorrycardTxt">
                  <h3 className={betpopup2?.res === "fail" ? "blueColor" : "yellowColor"}>{betpopup2?.res === "fail" ? "Sorry" : "Congratulations"}</h3>
                  <h4>{betpopup2?.res === "fail" ? "Loss" : "Bonus"}</h4>
                  <h5 className={betpopup2?.res === "fail" ? "blueColor" : "newredColor"}>&#8377;
                    {betpopup2?.ans === "green" &&
                      betpopup2?.color2 == "" &&
                      betpopup2?.res === "fail"
                      ? -(betpopup2?.amount - (betpopup2?.amount * 5) / 100)
                      : ""}
                    {betpopup2?.ans === "green" &&
                      betpopup2?.color2 == "" &&
                      betpopup2?.res === "success"
                      ? "+" +
                      (betpopup2?.amount * 2 -
                        (betpopup2?.amount * 2 * 5) / 100)
                      : ""}
                    {betpopup2?.ans === "violet" &&
                      betpopup2?.res === "fail"
                      ? -(betpopup2?.amount - (betpopup2?.amount * 5) / 100)
                      : ""}
                    {betpopup2?.ans === "violet" &&
                      betpopup2?.res === "success"
                      ? "+" +
                      (betpopup2?.amount * 5 -
                        (betpopup2?.amount * 5 * 5) / 100)
                      : ""}
                    {betpopup2?.ans === "red" &&
                      betpopup2?.color2 == "" &&
                      betpopup2?.res === "fail"
                      ? -(betpopup2?.amount - (betpopup2?.amount * 5) / 100)
                      : ""}
                    {betpopup2?.ans === "red" &&
                      betpopup2?.color2 == "" &&
                      betpopup2?.res === "success"
                      ? "+" +
                      (betpopup2?.amount * 2 -
                        (betpopup2?.amount * 2 * 5) / 100)
                      : ""}
                    {!isNaN(betpopup2?.ans) && betpopup2?.res === "fail"
                      ? -(betpopup2?.amount - (betpopup2?.amount * 5) / 100)
                      : ""}
                    {!isNaN(betpopup2?.ans) && betpopup2?.res === "success"
                      ? "+" +
                      (betpopup2?.amount * 8 -
                        (betpopup2?.amount * 8 * 5) / 100)
                      : ""}
                    {betpopup2?.ans == "red" &&
                      betpopup2?.color2 == "violet" &&
                      betpopup2?.res == "success"
                      ? "+" +
                      (betpopup2?.amount * 1.5 -
                        (betpopup2?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup2?.ans == "red" &&
                      betpopup2?.color2 == "violet" &&
                      betpopup2?.res == "fail"
                      ? -(betpopup2?.amount - (betpopup2?.amount * 5) / 100)
                      : ""}
                    {betpopup2?.ans == "green" &&
                      betpopup2?.color2 == "violet" &&
                      betpopup2?.res == "success"
                      ? "+" +
                      (betpopup2?.amount * 1.5 -
                        (betpopup2?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup2?.ans == "green" &&
                      betpopup2?.color2 == "violet" &&
                      betpopup2?.res == "fail"
                      ? -(betpopup2?.amount - (betpopup2?.amount * 5) / 100)
                      : ""}
                  </h5>
                  <h6>Period ID: {betpopup2?.period} </h6>
                </div>
              </div>
            }
          </div>
        }
        {activeTab === "tab3" &&
          <div>
            <div className="winTimebox">
              <div className="winTxtBox">
                <div className="winTxt">
                  <h5 onClick={() => { setRule(true) }}>How to Play</h5>
                  <h6>Win Go 3 Min</h6>
                </div>
                <Modal
                  open={rule}
                  onClose={() => {
                    setRule(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="diceRule">
                      <h2>How to Play</h2>
                    </div>
                    <div className="diceRuleInfo">
                      <h4>1 minutes 1 issue, 50 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <h4>2 minutes 2 issue, 100 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <button onClick={() => {
                        setRule(false)
                      }}>Close</button>
                    </div>
                  </Box>
                </Modal>
                <div className="winPlay">
                  <img src="one.png" alt="" />
                  <img src="three.png" alt="" />
                  <img src="two.png" alt="" />
                  <img src="eight.png" alt="" />
                  <img src="one.png" alt="" />
                </div>
              </div>
              <div className="winCount">
                <h5>Time Remaining</h5>
                <h3>
                  <span>0{threemin}</span> <span>:</span> <span>{printthreesec}</span>
                </h3>
                <h6>{periodID3?.period_3}</h6>
              </div>
            </div>
            <div className="hideParent">
              <div>
                <div className="winMinicont">
                  <div className="joinBtn">
                    <div>
                      <button
                        className={threemin === 0 && printthreesec <= "05" ? "greyBtn" : "joinBtn1"}
                        onClick={() => {
                          threemin === 0 && printthreesec <= "05"
                            ? setOpen(false)
                            : setOpen(true);
                        }}
                      >
                        Join Green
                      </button>
                      <Drawer open={open} anchor="bottom">
                        <div>
                          <div className="greenBot">
                            <h4>Win Go 3Min</h4>
                            <h5>Select Green</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === greenbtn?.id ? "clickedMoney" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setGreenbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  disabled={intvalue <= 1}
                                  onClick={() => {
                                    setIntvalue(intvalue - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue}</h3>
                                <button
                                  onClick={() => {
                                    setIntvalue(intvalue + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button onClick={() => {
                              setOpen(false)
                            }}>Cancel</button>
                            <button style={{ background: "#17b15e" }} onClick={joinGreen3}>Total Amount &#8377;{contractMoney}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          threemin === 0 && printthreesec < "05" ? "greyBtn" : "joinBtn2"
                        }
                        onClick={() => {
                          threemin === 0 && printthreesec < "05"
                            ? setOpen2(false)
                            : setOpen2(true);
                        }}
                      >
                        Join Violet
                      </button>
                      <Drawer open={open2} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#9b48db" }}>
                            <h4>Win Go 3Min</h4>
                            <h5>Select Violet</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === violetbtn?.id ? "violetColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setVioletbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#9b48db" }}
                                  disabled={intvalue2 <= 1}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue2}</h3>
                                <button
                                  style={{ background: "#9b48db" }}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen2(false);
                              }}>Cancel</button>
                            <button style={{ background: "#9b48db" }} onClick={joinViolet3}>Total Amount &#8377;{contractMoney2}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          threemin === 0 && printthreesec < "05" ? "greyBtn" : "joinBtn3"
                        }
                        onClick={() => {
                          threemin === 0 && printthreesec < "05"
                            ? setOpen3(false)
                            : setOpen3(true);
                        }}
                      >
                        Join Red
                      </button>
                      <Drawer open={open3} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#d23838" }}>
                            <h4>Win Go 3Min</h4>
                            <h5>Select Red</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === redbtn?.id ? "redColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setRedbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#d23838" }}
                                  disabled={intvalue3 <= 1}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue3}</h3>
                                <button
                                  style={{ background: "#d23838" }}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen3(false);
                              }}>Cancel</button>
                            <button style={{ background: "#d23838" }} onClick={joinRed3}>Total Amount &#8377;{contractMoney3}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                  <div className="winNumbers">
                    {numData?.map((item) => {
                      return (
                        <>
                          <img
                            src={item?.img}
                            alt=""
                            onClick={() => {
                              threemin === 0 && printthreesec <= "05"
                                ? setOpenNum(false)
                                : setOpenNum(true) || setOpenNum(item);
                            }}
                          />
                        </>
                      );
                    })}
                    <Drawer open={openNum} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#454e9e" }}>
                          <h4>Win Go</h4>
                          <h5>Select {openNum?.num}</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bluebtn?.id ? "numColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBluebtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#454e9e" }}
                                disabled={intvalue4 <= 1}
                                onClick={() => {
                                  setIntvalue4(intvalue4 - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvalue4}</h3>
                              <button
                                style={{ background: "#454e9e" }}
                                onClick={() => {
                                  setIntvalue4(intvalue4 + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenNum(false)
                          }}>Cancel</button>
                          <button style={{ background: "#454e9e", borderLeft: "2px solid white" }} onClick={joinBlue3}>Total Amount &#8377;{contractMoney4}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
                <div className="bigsmall">
                  <div className="bigsmall1">
                    <button
                      onClick={() => {
                        threemin === 0 && printthreesec <= "05"
                          ? setOpenBig(false)
                          : setOpenBig(true);
                      }}>Big</button>
                    <Drawer open={openBig} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#dd9138" }}>
                          <h4>Win Go</h4>
                          <h5>Select Big</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bigbtn?.id ? "bigColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBigbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#dd9138" }}
                                disabled={intvaluebig <= 1}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluebig}</h3>
                              <button
                                style={{ background: "#dd9138" }}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenBig(false)
                          }}>Cancel</button>
                          <button style={{ background: "#dd9138" }} onClick={joinBig3}>Total Amount &#8377;{contractMoneybig}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                  <div className="bigsmall2">
                    <button
                      onClick={() => {
                        threemin === 0 && printthreesec <= "05"
                          ? setOpenSmall(false)
                          : setOpenSmall(true);
                      }}
                    >Small</button>
                    <Drawer open={openSmall} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#5088d3" }}>
                          <h4>Win Go</h4>
                          <h5>Select Small</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === smallbtn?.id ? "smallMoney" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setSmallbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#5088d3" }}
                                disabled={intvaluesmall <= 1}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluesmall}</h3>
                              <button
                                style={{ background: "#5088d3" }}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenSmall(false)
                          }}>Cancel</button>
                          <button style={{ background: "#5088d3" }} onClick={joinSmall3}>Total Amount &#8377;{contractMoneysmall}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
              {/* {(periodID3?.period_3 === betpopup3?.period) && (threemin === 0 && printthreesec <= "05") && (threemin === 0 && printthreesec >= "03") &&
                <div className="hideBg">
                  <h4>{printthreesec}</h4>
                </div>
              } */}
              {(threemin === 0 && printthreesec <= "05") &&
                <div className="hideBg">
                  <h4>{printthreesec}</h4>
                </div>
              }
            </div>
            <Box
              sx={{
                width: "100%",
                marginTop: "15px"
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange2}
                aria-label="basic tabs example"
                className="gameTabs"
              >
                <Tab label="Parity Records" {...a11yProps(0)} />
                <Tab label="All Records" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>WinGo Record</h5>
                </div>
                <Paper>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Period</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Numbers</TableCell>
                          <TableCell align="right">Big Small</TableCell>
                          <TableCell align="right">Result</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resdata3?.map((item) => (
                          <TableRow key={item?.name}>
                            <TableCell component="th" scope="row">
                              {item?.period}
                            </TableCell>
                            <TableCell align="right">{item?.num}</TableCell>
                            <TableCell align="right">
                              <div>
                                <h4 style={{ paddingTop: "6px" }}
                                  className={
                                    item?.ans === "0"
                                      ? "redColor"
                                      : "greenColor" && item?.ans === "2"
                                        ? "redColor"
                                        : "greenColor" && item?.ans === "4"
                                          ? "redColor"
                                          : "greenColor" && item?.ans === "6"
                                            ? "redColor"
                                            : "greenColor" && item?.ans === "8"
                                              ? "redColor"
                                              : "greenColor"
                                  }
                                >
                                  {item?.ans}
                                </h4>
                              </div>
                            </TableCell>
                            <TableCell align="right">{item?.ans <= 4 ? 'Small' : 'Big'}</TableCell>
                            <TableCell align="right">
                              <div className="tableColor">
                                <h4
                                  className={
                                    item?.clo === "red" ? "colorRed" : "colorGreen"
                                  }
                                ></h4>
                                <h4
                                  className={item?.res1 === "violet" && "colorViolet"}
                                ></h4>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div className="pageBtn">
                  <button
                    onClick={() => setPageRes3(pageRes3 - 1)}
                    disabled={pageRes3 === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <button onClick={() => setPageRes3(pageRes3 + 1)}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </TabPanel >
            <TabPanel value={value} index={1}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>All Records</h5>
                </div>
                <div className="tableAll">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: "14px" }}>User</th>
                        <th style={{ padding: "14px" }}>Bet</th>
                        <th style={{ padding: "14px" }}>Multi.</th>
                        <th style={{ padding: "14px" }}>Cash Out</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {randomData?.map((item) => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  <i className="fa fa-user"
                                    style={{ fontSize: "15px" }}
                                  >
                                  </i> &nbsp;
                                  {item}
                                </div>
                              </>
                            )
                          })}
                        </td>
                        {randomData2?.map((item) => {
                          return (
                            <>
                              <div style={{ padding: "10px", margin: "1px" }}>
                                &#8377;{item}
                              </div>
                            </>
                          )
                        })}
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <div className="betCont">
              <div className="winRecord">
                <i className="fa fa-book"></i>
                <h5>My Record</h5>
              </div>
              <Paper>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {betdata3?.map((item) => {
                      return (
                        <>
                          <Accordion
                            expanded={expanded === item}
                            onChange={handleChange(item)}
                            sx={{
                              background: "#2b3270"
                            }}
                          >
                            <TableHead>
                              <TableRow className="betIcon">
                                <AccordionSummary
                                  expandIcon={
                                    <div className="expandIcon2">
                                      <i className="fa fa-angle-down"></i>
                                    </div>
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "98fr 2fr",
                                    background: "#2b3270"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      paddingLeft: "1px",
                                      flexShrink: 0,
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.period}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      marginLeft: "15px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.res}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {item?.ans === "violet" && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "violet" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 5 -
                                        (item?.amount * 5 * 5) / 100)
                                      : ""}

                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {!isNaN(item?.ans) && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {!isNaN(item?.ans) && item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 8 -
                                        (item?.amount * 8 * 5) / 100)
                                      : ""}

                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}

                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                  </Typography>
                                </AccordionSummary>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <AccordionDetails
                                  sx={{
                                    background: "#2b3270"
                                  }}
                                >
                                  <div className="betHead">
                                    <h4>Period Detail</h4>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Period</h6>
                                    <h6>{item?.period}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Contract Money</h6>
                                    <h6>{item?.amount}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Delivery</h6>
                                    <h6>
                                      {item?.amount - (item?.amount * 5) / 100}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Fee</h6>
                                    <h6>{(item?.amount * 5) / 100}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Open Price</h6>
                                    <h6>{item?.price}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Result</h6>
                                    <h6>
                                      {item?.number} {item?.color}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Select</h6>
                                    <h6>{item?.ans}</h6>
                                  </div>
                                  <div
                                    className={
                                      (item?.res === "fail" &&
                                        "redColor betRecord") ||
                                      (item?.res === "wait" &&
                                        "orangeColor betRecord") ||
                                      (item?.res === "success" &&
                                        "greenColor betRecord")
                                    }
                                  >
                                    <h6>Status</h6>
                                    <h5>{item?.res}</h5>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Amount</h6>
                                    <h6>
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {item?.ans === "violet" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "violet" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 5 -
                                          (item?.amount * 5 * 5) / 100)
                                        : ""}

                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {!isNaN(item?.ans) && item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {!isNaN(item?.ans) && item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 8 -
                                          (item?.amount * 8 * 5) / 100)
                                        : ""}

                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}

                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Create Time</h6>
                                    <h6>{item?.time}</h6>
                                  </div>
                                </AccordionDetails>
                              </TableRow>
                            </TableBody>
                          </Accordion>
                        </>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Paper>
              <div className="pageBtn">
                <button onClick={() => setPage3(page3 - 1)} disabled={page3 === 1}>
                  <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPage3(page3 + 1)}>
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
            {(periodID3?.period_3 === betpopup3?.period) && (threemin === 2 && printthreesec >= "58" || threemin === 3 && printthreesec === "00") &&
              <div className="sorrycard" style={winPopup}>
                {betpopup3?.res === "fail" ?
                  <img src="sorrycard.png" alt="" />
                  :
                  <img src="wincard.png" alt="" />
                }
                <div className="sorrycardTxt">
                  <h3 className={betpopup3?.res === "fail" ? "blueColor" : "yellowColor"}>{betpopup3?.res === "fail" ? "Sorry" : "Congratulations"}</h3>
                  <h4>{betpopup3?.res === "fail" ? "Loss" : "Bonus"}</h4>
                  <h5 className={betpopup3?.res === "fail" ? "blueColor" : "newredColor"}>&#8377;
                    {betpopup3?.ans === "green" &&
                      betpopup3?.color2 == "" &&
                      betpopup3?.res === "fail"
                      ? -(betpopup3?.amount - (betpopup3?.amount * 5) / 100)
                      : ""}
                    {betpopup3?.ans === "green" &&
                      betpopup3?.color2 == "" &&
                      betpopup3?.res === "success"
                      ? "+" +
                      (betpopup3?.amount * 2 -
                        (betpopup3?.amount * 2 * 5) / 100)
                      : ""}
                    {betpopup3?.ans === "violet" &&
                      betpopup3?.res === "fail"
                      ? -(betpopup3?.amount - (betpopup3?.amount * 5) / 100)
                      : ""}
                    {betpopup3?.ans === "violet" &&
                      betpopup3?.res === "success"
                      ? "+" +
                      (betpopup3?.amount * 5 -
                        (betpopup3?.amount * 5 * 5) / 100)
                      : ""}
                    {betpopup3?.ans === "red" &&
                      betpopup3?.color2 == "" &&
                      betpopup3?.res === "fail"
                      ? -(betpopup3?.amount - (betpopup3?.amount * 5) / 100)
                      : ""}
                    {betpopup3?.ans === "red" &&
                      betpopup3?.color2 == "" &&
                      betpopup3?.res === "success"
                      ? "+" +
                      (betpopup3?.amount * 2 -
                        (betpopup3?.amount * 2 * 5) / 100)
                      : ""}
                    {!isNaN(betpopup3?.ans) && betpopup3?.res === "fail"
                      ? -(betpopup3?.amount - (betpopup3?.amount * 5) / 100)
                      : ""}
                    {!isNaN(betpopup3?.ans) && betpopup3?.res === "success"
                      ? "+" +
                      (betpopup3?.amount * 8 -
                        (betpopup3?.amount * 8 * 5) / 100)
                      : ""}
                    {betpopup3?.ans == "red" &&
                      betpopup3?.color2 == "violet" &&
                      betpopup3?.res == "success"
                      ? "+" +
                      (betpopup3?.amount * 1.5 -
                        (betpopup3?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup3?.ans == "red" &&
                      betpopup3?.color2 == "violet" &&
                      betpopup3?.res == "fail"
                      ? -(betpopup3?.amount - (betpopup3?.amount * 5) / 100)
                      : ""}
                    {betpopup3?.ans == "green" &&
                      betpopup3?.color2 == "violet" &&
                      betpopup3?.res == "success"
                      ? "+" +
                      (betpopup3?.amount * 1.5 -
                        (betpopup3?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup3?.ans == "green" &&
                      betpopup3?.color2 == "violet" &&
                      betpopup3?.res == "fail"
                      ? -(betpopup3?.amount - (betpopup3?.amount * 5) / 100)
                      : ""}
                  </h5>
                  <h6>Period ID: {betpopup3?.period} </h6>
                </div>
              </div>
            }
          </div>
        }
        {activeTab === "tab4" &&
          <div>
            <div className="winTimebox">
              <div className="winTxtBox">
                <div className="winTxt">
                  <h5 onClick={() => { setRule(true) }}>How to Play</h5>
                  <h6>Win Go 5 Min</h6>
                </div>
                <Modal
                  open={rule}
                  onClose={() => {
                    setRule(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="diceRule">
                      <h2>How to Play</h2>
                    </div>
                    <div className="diceRuleInfo">
                      <h4>1 minutes 1 issue, 50 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <h4>2 minutes 2 issue, 100 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                      <button onClick={() => {
                        setRule(false)
                      }}>Close</button>
                    </div>
                  </Box>
                </Modal>
                <div className="winPlay">
                  <img src="one.png" alt="" />
                  <img src="three.png" alt="" />
                  <img src="two.png" alt="" />
                  <img src="eight.png" alt="" />
                  <img src="one.png" alt="" />
                </div>
              </div>
              <div className="winCount">
                <h5>Time Remaining</h5>
                <h3>
                  <span>0{fivemin}</span> <span>:</span> <span>{printfivesec}</span>
                </h3>
                <h6>{periodID4?.period_4}</h6>
              </div>
            </div>
            <div className="hideParent">
              <div>
                <div className="winMinicont">
                  <div className="joinBtn">
                    <div>
                      <button
                        className={fivemin === 0 && printfivesec <= "05" ? "greyBtn" : "joinBtn1"}
                        onClick={() => {
                          fivemin === 0 && printfivesec <= "05"
                            ? setOpen(false)
                            : setOpen(true);
                        }}
                      >
                        Join Green
                      </button>
                      <Drawer open={open} anchor="bottom">
                        <div>
                          <div className="greenBot">
                            <h4>Win Go 5Min</h4>
                            <h5>Select Green</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === greenbtn?.id ? "clickedMoney" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setGreenbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  disabled={intvalue <= 1}
                                  onClick={() => {
                                    setIntvalue(intvalue - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue}</h3>
                                <button
                                  onClick={() => {
                                    setIntvalue(intvalue + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button onClick={() => {
                              setOpen(false)
                            }}>Cancel</button>
                            <button style={{ background: "#17b15e" }} onClick={joinGreen4}>Total Amount &#8377;{contractMoney}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          fivemin === 0 && printfivesec < "05" ? "greyBtn" : "joinBtn2"
                        }
                        onClick={() => {
                          fivemin === 0 && printfivesec < "05"
                            ? setOpen2(false)
                            : setOpen2(true);
                        }}
                      >
                        Join Violet
                      </button>
                      <Drawer open={open2} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#9b48db" }}>
                            <h4>Win Go 5Min</h4>
                            <h5>Select Violet</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === violetbtn?.id ? "violetColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setVioletbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#9b48db" }}
                                  disabled={intvalue2 <= 1}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue2}</h3>
                                <button
                                  style={{ background: "#9b48db" }}
                                  onClick={() => {
                                    setIntvalue2(intvalue2 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen2(false);
                              }}>Cancel</button>
                            <button style={{ background: "#9b48db" }} onClick={joinViolet4}>Total Amount &#8377;{contractMoney2}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                    <div>
                      <button
                        className={
                          fivemin === 0 && printfivesec < "05" ? "greyBtn" : "joinBtn3"
                        }
                        onClick={() => {
                          fivemin === 0 && printfivesec < "05"
                            ? setOpen3(false)
                            : setOpen3(true);
                        }}
                      >
                        Join Red
                      </button>
                      <Drawer open={open3} anchor="bottom">
                        <div>
                          <div className="greenBot"
                            style={{ background: "#d23838" }}>
                            <h4>Win Go 5Min</h4>
                            <h5>Select Red</h5>
                          </div>
                          <div className="greenBox">
                            <div className="greenBal">
                              <div>
                                <h4>Balance</h4>
                              </div>
                              <div className="greenMoney">
                                {moneydata?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          item?.id === redbtn?.id ? "redColor" : ""
                                        }
                                      >
                                        <button
                                          onClick={() => {
                                            setRedbtn(item);
                                          }}
                                        >
                                          {item?.money}
                                        </button>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="greenBal">
                              <div>
                                <h4>Quantity</h4>
                              </div>
                              <div className="greenQuantity">
                                <button
                                  style={{ background: "#d23838" }}
                                  disabled={intvalue3 <= 1}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 - 1);
                                  }}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue3}</h3>
                                <button
                                  style={{ background: "#d23838" }}
                                  onClick={() => {
                                    setIntvalue3(intvalue3 + 1);
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div className="greenSale">
                              <h4>
                                <i className="fa fa-check"></i>I agree
                                <span>PRESALE RULE</span>
                              </h4>
                            </div>
                          </div>
                          <div className="greenBtns">
                            <button
                              onClick={() => {
                                setOpen3(false);
                              }}>Cancel</button>
                            <button style={{ background: "#d23838" }} onClick={joinRed4}>Total Amount &#8377;{contractMoney3}</button>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                  <div className="winNumbers">
                    {numData?.map((item) => {
                      return (
                        <>
                          <img
                            src={item?.img}
                            alt=""
                            onClick={() => {
                              fivemin === 0 && printfivesec <= "05"
                                ? setOpenNum(false)
                                : setOpenNum(true) || setOpenNum(item);
                            }}
                          />
                        </>
                      );
                    })}
                    <Drawer open={openNum} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#454e9e" }}>
                          <h4>Win Go</h4>
                          <h5>Select {openNum?.num}</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bluebtn?.id ? "numColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBluebtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#454e9e" }}
                                disabled={intvalue4 <= 1}
                                onClick={() => {
                                  setIntvalue4(intvalue4 - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvalue4}</h3>
                              <button
                                style={{ background: "#454e9e" }}
                                onClick={() => {
                                  setIntvalue4(intvalue4 + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenNum(false)
                          }}>Cancel</button>
                          <button style={{ background: "#454e9e", borderLeft: "2px solid white" }} onClick={joinBlue4}>Total Amount &#8377;{contractMoney4}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
                <div className="bigsmall">
                  <div className="bigsmall1">
                    <button
                      onClick={() => {
                        fivemin === 0 && printfivesec <= "05"
                          ? setOpenBig(false)
                          : setOpenBig(true);
                      }}>Big</button>
                    <Drawer open={openBig} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#dd9138" }}>
                          <h4>Win Go</h4>
                          <h5>Select Big</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === bigbtn?.id ? "bigColor" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setBigbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#dd9138" }}
                                disabled={intvaluebig <= 1}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluebig}</h3>
                              <button
                                style={{ background: "#dd9138" }}
                                onClick={() => {
                                  setIntvaluebig(intvaluebig + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenBig(false)
                          }}>Cancel</button>
                          <button style={{ background: "#dd9138" }} onClick={joinBig4}>Total Amount &#8377;{contractMoneybig}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                  <div className="bigsmall2">
                    <button
                      onClick={() => {
                        fivemin === 0 && printfivesec <= "05"
                          ? setOpenSmall(false)
                          : setOpenSmall(true);
                      }}
                    >Small</button>
                    <Drawer open={openSmall} anchor="bottom">
                      <div>
                        <div className="greenBot" style={{ background: "#5088d3" }}>
                          <h4>Win Go</h4>
                          <h5>Select Small</h5>
                        </div>
                        <div className="greenBox">
                          <div className="greenBal">
                            <div>
                              <h4>Balance</h4>
                            </div>
                            <div className="greenMoney">
                              {moneydata?.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        item?.id === smallbtn?.id ? "smallMoney" : ""
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          setSmallbtn(item);
                                        }}
                                      >
                                        {item?.money}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="greenBal">
                            <div>
                              <h4>Quantity</h4>
                            </div>
                            <div className="greenQuantity">
                              <button
                                style={{ background: "#5088d3" }}
                                disabled={intvaluesmall <= 1}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall - 1);
                                }}
                              >
                                <i className="fa fa-minus"></i>
                              </button>
                              <h3>{intvaluesmall}</h3>
                              <button
                                style={{ background: "#5088d3" }}
                                onClick={() => {
                                  setIntvaluesmall(intvaluesmall + 1);
                                }}
                              >
                                <i className="fa fa-plus"></i>
                              </button>
                            </div>
                          </div>
                          <div className="greenSale">
                            <h4>
                              <i className="fa fa-check"></i>I agree
                              <span>PRESALE RULE</span>
                            </h4>
                          </div>
                        </div>
                        <div className="greenBtns">
                          <button onClick={() => {
                            setOpenSmall(false)
                          }}>Cancel</button>
                          <button style={{ background: "#5088d3" }} onClick={joinSmall4}>Total Amount &#8377;{contractMoneysmall}</button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                </div>
              </div>
              {/* {(periodID4?.period_4 === betpopup4?.period) && (fivemin === 0 && printfivesec <= "05") && (fivemin === 0 && printfivesec >= "03") &&
                <div className="hideBg">
                  <h4>{printfivesec}</h4>
                </div>
              } */}
              {(fivemin === 0 && printfivesec <= "05") &&
                <div className="hideBg">
                  <h4>{printfivesec}</h4>
                </div>
              }
            </div>
            <Box
              sx={{
                width: "100%",
                marginTop: "15px"
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange2}
                aria-label="basic tabs example"
                className="gameTabs"
              >
                <Tab label="Parity Records" {...a11yProps(0)} />
                <Tab label="All Records" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>WinGo Record</h5>
                </div>
                <Paper>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Period</TableCell>
                          <TableCell align="right">Price</TableCell>
                          <TableCell align="right">Numbers</TableCell>
                          <TableCell align="right">Big Small</TableCell>
                          <TableCell align="right">Result</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resdata4?.map((item) => (
                          <TableRow key={item?.name}>
                            <TableCell component="th" scope="row">
                              {item?.period}
                            </TableCell>
                            <TableCell align="right">{item?.num}</TableCell>
                            <TableCell align="right">
                              <div>
                                <h4 style={{ paddingTop: "6px" }}
                                  className={
                                    item?.ans === "0"
                                      ? "redColor"
                                      : "greenColor" && item?.ans === "2"
                                        ? "redColor"
                                        : "greenColor" && item?.ans === "4"
                                          ? "redColor"
                                          : "greenColor" && item?.ans === "6"
                                            ? "redColor"
                                            : "greenColor" && item?.ans === "8"
                                              ? "redColor"
                                              : "greenColor"
                                  }
                                >
                                  {item?.ans}
                                </h4>
                              </div>
                            </TableCell>
                            <TableCell align="right">{item?.ans <= 4 ? 'Small' : 'Big'}</TableCell>
                            <TableCell align="right">
                              <div className="tableColor">
                                <h4
                                  className={
                                    item?.clo === "red" ? "colorRed" : "colorGreen"
                                  }
                                ></h4>
                                <h4
                                  className={item?.res1 === "violet" && "colorViolet"}
                                ></h4>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div className="pageBtn">
                  <button
                    onClick={() => setPageRes4(pageRes4 - 1)}
                    disabled={pageRes4 === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <button onClick={() => setPageRes4(pageRes4 + 1)}>
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </TabPanel >
            <TabPanel value={value} index={1}>
              <div className="resCont">
                <div className="winRecord">
                  <i className="fa fa-trophy"></i>
                  <h5>All Records</h5>
                </div>
                <div className="tableAll">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: "14px" }}>User</th>
                        <th style={{ padding: "14px" }}>Bet</th>
                        <th style={{ padding: "14px" }}>Multi.</th>
                        <th style={{ padding: "14px" }}>Cash Out</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {randomData?.map((item) => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  <i className="fa fa-user"
                                    style={{ fontSize: "15px" }}
                                  >
                                  </i> &nbsp;
                                  {item}
                                </div>
                              </>
                            )
                          })}
                        </td>
                        {randomData2?.map((item) => {
                          return (
                            <>
                              <div style={{ padding: "10px", margin: "1px" }}>
                                &#8377;{item}
                              </div>
                            </>
                          )
                        })}
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                        <td>
                          {randomData2?.map(() => {
                            return (
                              <>
                                <div style={{ padding: "10px", margin: "1px" }}>
                                  --
                                </div>
                              </>
                            )
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <div className="betCont">
              <div className="winRecord">
                <i className="fa fa-book"></i>
                <h5>My Record</h5>
              </div>
              <Paper>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {betdata4?.map((item) => {
                      return (
                        <>
                          <Accordion
                            expanded={expanded === item}
                            onChange={handleChange(item)}
                            sx={{ background: "#2b3270" }}
                          >
                            <TableHead>
                              <TableRow className="betIcon">
                                <AccordionSummary
                                  expandIcon={
                                    <div className="expandIcon2">
                                      <i className="fa fa-angle-down"></i>
                                    </div>
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  sx={{
                                    display: "grid",
                                    gridTemplateColumns: "98fr 2fr",
                                    background: "#2b3270"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      paddingLeft: "1px",
                                      flexShrink: 0,
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.period}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      marginLeft: "15px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item?.res}
                                  </Typography>
                                  <Typography
                                    className={
                                      (item?.res === "fail" && "redColor") ||
                                      (item?.res === "wait" && "orangeColor") ||
                                      (item?.res === "success" && "greenColor")
                                    }
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "green" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {item?.ans === "violet" && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "violet" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 5 -
                                        (item?.amount * 5 * 5) / 100)
                                      : ""}

                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {item?.ans === "red" &&
                                      item?.color2 == "" &&
                                      item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                      : ""}

                                    {!isNaN(item?.ans) && item?.res === "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                    {!isNaN(item?.ans) && item?.res === "success"
                                      ? "+" +
                                      (item?.amount * 8 -
                                        (item?.amount * 8 * 5) / 100)
                                      : ""}

                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "red" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}

                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "success"
                                      ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                      : ""}
                                    {item?.ans == "green" &&
                                      item?.color2 == "violet" &&
                                      item?.res == "fail"
                                      ? -(item?.amount - (item?.amount * 5) / 100)
                                      : ""}
                                  </Typography>
                                </AccordionSummary>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <AccordionDetails
                                  sx={{
                                    background: "#2b3270"
                                  }}
                                >
                                  <div className="betHead">
                                    <h4>Period Detail</h4>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Period</h6>
                                    <h6>{item?.period}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Contract Money</h6>
                                    <h6>{item?.amount}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Delivery</h6>
                                    <h6>
                                      {item?.amount - (item?.amount * 5) / 100}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Fee</h6>
                                    <h6>{(item?.amount * 5) / 100}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Open Price</h6>
                                    <h6>{item?.price}</h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Result</h6>
                                    <h6>
                                      {item?.number} {item?.color}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Select</h6>
                                    <h6>{item?.ans}</h6>
                                  </div>
                                  <div
                                    className={
                                      (item?.res === "fail" &&
                                        "redColor betRecord") ||
                                      (item?.res === "wait" &&
                                        "orangeColor betRecord") ||
                                      (item?.res === "success" &&
                                        "greenColor betRecord")
                                    }
                                  >
                                    <h6>Status</h6>
                                    <h5>{item?.res}</h5>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Amount</h6>
                                    <h6>
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "green" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {item?.ans === "violet" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "violet" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 5 -
                                          (item?.amount * 5 * 5) / 100)
                                        : ""}

                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {item?.ans === "red" &&
                                        item?.color2 == "" &&
                                        item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 2 -
                                          (item?.amount * 2 * 5) / 100)
                                        : ""}

                                      {!isNaN(item?.ans) && item?.res === "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                      {!isNaN(item?.ans) && item?.res === "success"
                                        ? "+" +
                                        (item?.amount * 8 -
                                          (item?.amount * 8 * 5) / 100)
                                        : ""}

                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "red" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}

                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "success"
                                        ? "+" +
                                        (item?.amount * 1.5 -
                                          (item?.amount * 1.5 * 5) / 100)
                                        : ""}
                                      {item?.ans == "green" &&
                                        item?.color2 == "violet" &&
                                        item?.res == "fail"
                                        ? -(item?.amount - (item?.amount * 5) / 100)
                                        : ""}
                                    </h6>
                                  </div>
                                  <div className="betRecord">
                                    <h6>Create Time</h6>
                                    <h6>{item?.time}</h6>
                                  </div>
                                </AccordionDetails>
                              </TableRow>
                            </TableBody>
                          </Accordion>
                        </>
                      );
                    })}
                  </Table>
                </TableContainer>
              </Paper>
              <div className="pageBtn">
                <button onClick={() => setPage4(page4 - 1)} disabled={page4 === 1}>
                  <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPage4(page4 + 1)}>
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
            {(periodID4?.period_4 === betpopup4?.period) && (fivemin === 4 && printfivesec >= "58" || fivemin === 5 && printfivesec === "00") &&
              <div className="sorrycard" style={winPopup}>
                {betpopup4?.res === "fail" ?
                  <img src="sorrycard.png" alt="" />
                  :
                  <img src="wincard.png" alt="" />
                }
                <div className="sorrycardTxt">
                  <h3 className={betpopup4?.res === "fail" ? "blueColor" : "yellowColor"}>{betpopup4?.res === "fail" ? "Sorry" : "Congratulations"}</h3>
                  <h4>{betpopup4?.res === "fail" ? "Loss" : "Bonus"}</h4>
                  <h5 className={betpopup4?.res === "fail" ? "blueColor" : "newredColor"}>&#8377;
                    {betpopup4?.ans === "green" &&
                      betpopup4?.color2 == "" &&
                      betpopup4?.res === "fail"
                      ? -(betpopup4?.amount - (betpopup4?.amount * 5) / 100)
                      : ""}
                    {betpopup4?.ans === "green" &&
                      betpopup4?.color2 == "" &&
                      betpopup4?.res === "success"
                      ? "+" +
                      (betpopup4?.amount * 2 -
                        (betpopup4?.amount * 2 * 5) / 100)
                      : ""}
                    {betpopup4?.ans === "violet" &&
                      betpopup4?.res === "fail"
                      ? -(betpopup4?.amount - (betpopup4?.amount * 5) / 100)
                      : ""}
                    {betpopup4?.ans === "violet" &&
                      betpopup4?.res === "success"
                      ? "+" +
                      (betpopup4?.amount * 5 -
                        (betpopup4?.amount * 5 * 5) / 100)
                      : ""}
                    {betpopup4?.ans === "red" &&
                      betpopup4?.color2 == "" &&
                      betpopup4?.res === "fail"
                      ? -(betpopup4?.amount - (betpopup4?.amount * 5) / 100)
                      : ""}
                    {betpopup4?.ans === "red" &&
                      betpopup4?.color2 == "" &&
                      betpopup4?.res === "success"
                      ? "+" +
                      (betpopup4?.amount * 2 -
                        (betpopup4?.amount * 2 * 5) / 100)
                      : ""}
                    {!isNaN(betpopup4?.ans) && betpopup4?.res === "fail"
                      ? -(betpopup4?.amount - (betpopup4?.amount * 5) / 100)
                      : ""}
                    {!isNaN(betpopup4?.ans) && betpopup4?.res === "success"
                      ? "+" +
                      (betpopup4?.amount * 8 -
                        (betpopup4?.amount * 8 * 5) / 100)
                      : ""}
                    {betpopup4?.ans == "red" &&
                      betpopup4?.color2 == "violet" &&
                      betpopup4?.res == "success"
                      ? "+" +
                      (betpopup4?.amount * 1.5 -
                        (betpopup4?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup4?.ans == "red" &&
                      betpopup4?.color2 == "violet" &&
                      betpopup4?.res == "fail"
                      ? -(betpopup4?.amount - (betpopup4?.amount * 5) / 100)
                      : ""}
                    {betpopup4?.ans == "green" &&
                      betpopup4?.color2 == "violet" &&
                      betpopup4?.res == "success"
                      ? "+" +
                      (betpopup4?.amount * 1.5 -
                        (betpopup4?.amount * 1.5 * 5) / 100)
                      : ""}
                    {betpopup4?.ans == "green" &&
                      betpopup4?.color2 == "violet" &&
                      betpopup4?.res == "fail"
                      ? -(betpopup4?.amount - (betpopup4?.amount * 5) / 100)
                      : ""}
                  </h5>
                  <h6>Period ID: {betpopup4?.period} </h6>
                </div>
              </div>
            }
          </div>
        }
        {/* <div className="audio">
          {minute === 0 && second < 11 && (
            <ReactAudioPlayer
              src="WhatsApp Audio 2023-09-19 at 02.12.53 (1).mpeg"
              autoPlay
              controls
            />
          )}
        </div> */}
      </div>
    </>
  );
}

// Win CSS
